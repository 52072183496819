<template>
  <div class="table-img">
    <el-image style="width: 50px; height: 50px"
              :z-index="2001"
              fit="contain"
              :src="url+'/width100'"
              :preview-src-list="srcList">
      <div slot="error"
           class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
    </el-image>
  </div>
</template>

<script>
export default {
  props: ['url'],
  data () {
    return {

    }
  },
  computed: {
    srcList () {
      return [this.url]
    }
  },
  components: {
  },
  methods: {

  },
  mounted () {

  }
}
</script>

<style lang="scss">
.table-img {
  .image-slot {
    width: 100%;
    height: 100%;
    background: #f5f7fa;
    color: #909399;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.el-icon-circle-close {
  color: white;
}
</style>
