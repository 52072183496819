<template>
  <div class="">
    <el-dialog title="设置奖品"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="60%">
      <el-form :model="form"
               :rules="rules"
               label-position="top"
               ref="form">
        <el-form-item label="礼品id"
                      prop="gift_id">
          <el-input type="text"
                    class="mr-15"
                    placeholder="礼品id"
                    v-model="form.gift_id"></el-input>
        </el-form-item>
        <el-form-item label="礼品类型"
                      prop="type">
          <el-select v-model="form.type"
                     @change="typeChange()"
                     class="width100"
                     placeholder="请选择">
            <el-option v-for="(item,index) in prizeTypes"
                       :key="index"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="红包金额"
                      v-if="form.type === 'ENVELOPE'"
                      prop="money">
          <el-input type="text"
                    placeholder="红包金额"
                    v-model="form.money"></el-input>
        </el-form-item>
        <el-form-item label="充值金额"
                      v-if="form.type === 'EXPENSES'"
                      prop="expenses">
          <el-input type="text"
                    placeholder="充值金额"
                    v-model="form.expenses"></el-input>
        </el-form-item>
        <el-form-item label="卡密id"
                      v-if="form.type === 'CARD_KEY'"
                      prop="card_id">
          <el-input type="text"
                    placeholder="请输入卡密id"
                    v-model="form.card_id"></el-input>
        </el-form-item>
        <el-form-item label="第三方ID"
                      v-if="form.type === 'RECHARGE'"
                      prop="recharge_proid">
          <el-input type="text"
                    placeholder="请输入第三方ID"
                    v-model="form.recharge_proid"></el-input>
        </el-form-item>
        <el-form-item label="代金券id"
                      v-if="form.type === 'COUPON'"
                      prop="coupon_id">
          <el-select v-model="form.coupon_id"
                     filterable
                     class="width100"
                     remote
                     @change="couponChange"
                     placeholder="请输入批次号"
                     :remote-method="remoteMethod">
            <el-option v-for="item in options"
                       :key="item.id"
                       :label="item.id"
                       :value="item.id">
              {{item.title}}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="奖品名称"
                      v-if="form.type === 'KIND' || form.type === 'VERIFY' || form.type === 'EXPENSES'|| form.type === 'CARD_KEY' || form.type === 'RECHARGE'"
                      prop="name">
          <el-input type="text"
                    placeholder="请输入奖品名称"
                    v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="奖品数量"
                      prop="quantity">
          <el-input type="text"
                    placeholder="请输入奖品数量"
                    v-model="form.quantity"></el-input>
        </el-form-item>
        <el-form-item label="特殊奖品">
          <el-checkbox v-model="form.priority"
                       :true-label="1"
                       :false-label="2">特殊奖品</el-checkbox>
        </el-form-item>
        <el-form-item label="奖品图片"
                      prop="img_id">
          <ImgUpload :fileList="imgList"
                     @on-success="imgUploadSuccess"
                     v-model="form.img_id" />
        </el-form-item>
        <el-form-item label="使用说明">
          <el-input type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入使用说明"
                    v-model="form.recharge_data">
          </el-input>
        </el-form-item>

        <el-form-item class="tr">
          <el-button type="primary"
                     @click="submitForm('form')">立即{{index>=0?'修改':'创建'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

  </div>
</template>

<script>
import { fetchVouchers, questionLuckySetPrize } from '@/api/market'
import ImgUpload from '@/components/ImgUpload'
export default {
  name: '',
  data () {
    return {
      imgList: [],
      dialogVisible: false,
      id: '', // 活动id
      form: {
        gift_id: '',
        name: '',
        type: '',
        img: {},
        img_id: '',
        coupon_id: '',
        quantity: '',
        money: '',
        priority: 2,
        expenses: 0,
        card_id: '',
        recharge_proid: '',
        recharge_data: ''
      },
      rules: {
        gift_id: [
          { required: true, message: '请输入礼品id', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '请选择奖品类型', trigger: 'change' }
        ],
        money: [
          { required: true, message: '请输入红包金额', trigger: 'blur' }
        ],
        coupon_id: [
          { required: true, message: '请输入代金券批次号搜索', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入奖品名称', trigger: 'blur' }
        ],
        quantity: [
          { required: true, message: '请输入奖品数量', trigger: 'blur' }
        ],
        img_id: [
          { required: true, message: '请上传奖品图片', trigger: 'change' }
        ],
        expenses: [
          { required: true, message: '请输入充值金额', trigger: 'blur' }
        ],
        card_id: [
          {
            required: true, message: '请输入卡密id', trigger: 'blur'
          }
        ],
        recharge_proid: [
          {
            required: true, message: '请输入第三方ID', trigger: 'blur'
          }
        ]
      },
      prizeTypes: [
        {
          value: 'ENVELOPE',
          label: '红包'
        },
        {
          value: 'COUPON',
          label: '代金券'
        },
        {
          value: 'KIND',
          label: '邮寄实物'
        },
        {
          value: 'VERIFY',
          label: '网点核销'
        },
        {
          value: 'EXPENSES',
          label: '话费充值'
        },
        {
          value: 'CARD_KEY',
          label: '卡密'
        },
        {
          value: 'RECHARGE',
          label: '直充会员'
        }
      ],
      index: -1,
      options: []
    }
  },
  components: {
    ImgUpload
  },
  methods: {
    couponChange (val) {
      const res = this.options.find((item) => {
        return item.id === val
      })
      this.form.name = res.title
    },
    typeChange () {
      this.form.name = ''
      this.form.coupon_id = ''
      this.$refs.form.clearValidate()
    },
    async remoteMethod (query) {
      if (query !== '') {
        const res = await fetchVouchers({
          coupon_stock_id: query
        })
        if (res.meta.code === 0) {
          this.options = res.data.coupons
        }
      } else {
        this.options = []
      }
    },
    imgUploadSuccess (res) {
      this.form.img = res[0]
    },
    showDialog (scpoe, id) {
      this.dialogVisible = true
      this.id = id
      if (scpoe) {
        this.index = scpoe.$index
        this.imgList = [scpoe.row.img]
        const formData = Object.assign({}, scpoe.row)
        formData.money = formData.money / 100
        formData.expenses = formData.expenses / 100
        formData.img_id = formData.img && formData.img.id
        this.form = formData
      } else {
        this.form = {
          gift_id: '',
          name: '',
          type: '',
          img_id: '',
          img: {},
          coupon_id: '',
          quantity: '',
          money: '',
          priority: 2,
          expenses: 0,
          card_id: '',
          recharge_proid: '',
          recharge_data: ''
        }
        this.index = -1
        this.imgList = []
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },

    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const formData = JSON.parse(JSON.stringify(this.form))
          formData.money = formData.money * 100
          formData.expenses = formData.expenses * 100
          const res = await questionLuckySetPrize({ activity_id: this.id, ...formData })
          if (res.meta.code === 0) {
            this.$emit('on-success')
            this.dialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          this.$message.error('表单信息填写有误, 请检查')
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
